import React, { useState, useRef } from 'react'
import CameraIcon from '@mui/icons-material/Camera';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import './App.css';
import './../Fonts/Noteworthy-Lt.woff'
import 'react-phone-number-input/style.css'
import 'react-html5-camera-photo/build/css/index.css';
import PhoneInput from 'react-phone-number-input'
import Loading from 'react-fullscreen-loading';
import Checkbox from '@mui/material/Checkbox';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { isValidPhoneNumber } from 'react-phone-number-input'
import TermsAndConditionsModal from './TermsAndConditionsModal';
import { validateEmail } from '../utils';
import { useParams, useSearchParams } from 'react-router-dom';


// Set the AWS Region
const REGION = "eu-central-1"; //REGION
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: 'eu-central-1:93b01e70-51cf-41c3-bba0-210a580da87c',
  }),
});

const uploadedPhotoPrefix = "https://btempbuck.s3.eu-central-1.amazonaws.com/";
const apiurl = "https://h601pm91w0.execute-api.eu-central-1.amazonaws.com/prod/public/events";

const steps = ['Pic It', 'Phone', 'Smile'];

const EventsMenu = ({events, setEvent}) => {
  return (
    <Grid container spacing={2} className="App MobileApp">
      <Grid item xs={12}>
      <Typography color="white" fontSize={24}>Which event are you attending?</Typography>
      {events.map((e) => {        
        return (
            <Grid item xs={12}>
              <Typography color="white" fontSize={24}>
                  <Button onClick={() => setEvent(e.event_id)}>
                  {e.name}
                </Button>
            </Typography>
            </Grid>          
        )        
      })}
      </Grid>
      </Grid>
  )
}

function App() {
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [email, setEmailValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validatePhone, setValidatePhone] = useState(true);
  const [photo, setPhoto] = useState("");
  const hiddenFileInput = useRef(null);
  const [agree, setAgree] = useState(false);
  const [agreeValidate, setAgreeValidate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [openEvents, setOpenEvents] = React.useState([]);

  const [event, setEvent] = useState("");

  React.useEffect(() => {
    handleLoadEvents(getEventID())
  }, []);

  const getEventID = () => {
    const path = window.location.pathname;
    const segments = path.split('/');
    if (segments.length == 3 && segments[0] == '' && segments[1] == 'event') {
      const id = segments[segments.length - 1];
      return (id)
    }
  }
  const handleLoadEvents = (id) => {

    const payload = {
      queryStringParameters: {
        type: "list"
      }
    };

    fetch(apiurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.body !== undefined) {
          let eventResponse = JSON.parse(data.body)
          if (eventResponse?.events !== undefined) {
            setOpenEvents(eventResponse.events)
            const exists = eventResponse.events.some(event => event.event_id === id);
            if (exists)
              setEvent(id)
            console.log(eventResponse.events)
          }
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  const handleFileInput = (e) => {
    console.log("start uploading" + e.target.files[0])
    handleUpload(e.target.files[0]);
  }

  const handleUpload = async (file) => {
    console.log("start uploading");
    const fileext = file.name.split('.').pop();
    const photoKey = uuidv4() + "." + fileext;
    const uploadParams = {
      Bucket: "btempbuck",
      Key: photoKey,
      Body: file,
      Tagging: "x-picit-tag-phone=" + phoneNumber + "&x-picit-tag-mail=" + email + "&x-picit-tag-eventid=" + event
    };
    console.log(uploadParams)

    try {
      setLoading(true);
      await s3.send(new PutObjectCommand(uploadParams));
      debugger;
      setPhoto(`${uploadedPhotoPrefix}${photoKey}`)
    } catch (err) {
      return alert("There was an error uploading your photo: ", err.message);
    } finally {
      setLoading(false);
    }
  }

  const handleCapture = event => {
    hiddenFileInput.current.click();
  };

  const handleAgree = (event) => {
    setAgree(event.target.checked);
  };

  const handleNext = () => {
    let moveNext = false;
    switch (activeStep) {
      case 0:
        if (agree) {
          moveNext = true;
        } else {
          setAgreeValidate(true);
        }
        break;
      case 1:
        let validate;
        if (phoneNumber) {
          validate = isValidPhoneNumber(phoneNumber);
        } else if (email) {
          validate = validateEmail(email);
        }
        else {
          validate = false;
        }
        setValidatePhone(validate);
        moveNext = validate;
        break;
      case 2:
        if (photo !== "") {
          moveNext = true;
        }
        break;
      default:
        break;
    }

    if (moveNext) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOpen = (event) => {
    event.preventDefault();
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);


  const stepsComponents = [
    <Grid container spacing={2} className="App MobileApp">
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography color="white" fontSize={24}>Hey there, snapshot superstar! 📱✨</Typography>
          <Typography color="white" fontSize={24}>Attending an awesome event? Want to snag those perfect pics without the hassle? PicIt's got you covered!</Typography>
        </Grid>
        <br></br>
        <Grid item xs={12}>
          <Typography color="white" fontSize={24}>Why PicIt?</Typography>
        </Grid>
        <br></br>
        <Grid item xs={12}>
          <Typography color="white" fontSize={24}>•⁠  ⁠🔍 Get only YOUR photos</Typography>
          <Typography color="white" fontSize={24}>•⁠  ⁠⚡ Access pics during the event</Typography>
          <Typography color="white" fontSize={24}>•⁠  ⁠🤖 AI-curated custom gallery</Typography>
          <br></br>
          <Typography color="white" fontSize={24}></Typography>
          <Typography color="white" fontSize={24}></Typography>
          <Typography color="white" fontSize={24}></Typography>
        </Grid>
        <br></br>
        <Grid item xs={12}>
          <Typography color="white" fontSize={24}></Typography>
          <Typography color="white" fontSize={24}></Typography>
        </Grid>
        <Grid item xs={12}>
          <h2 className="AppSecondTitle">So Picit</h2>
        </Grid>
        <Grid item xs={12} className="termsAndCond">
          <Checkbox
            checked={agree}
            onChange={handleAgree}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: 'white',
              },
            }}
          />
          <Typography color={agreeValidate ? 'red' : 'white'}>
            <span>I Agree </span>
            <a href='/' onClick={handleOpen}>Terms of use</a>
          </Typography>
          <TermsAndConditionsModal
            open={openModal}
            setOpen={setOpenModal}
            handleClose={handleCloseModal}
          />
        </Grid>
      </Grid>
    </Grid>,
    <Grid container spacing={2} className="App MobileApp">
      <Grid item fontSize={20} xs={12}>
        <Grid item>
          <Typography color="white" fontSize={24}>How It Works:</Typography>
          <br></br>
          <Typography color="white" fontSize={24}>1.⁠ ⁠😃 Flash that smile & share your contact info</Typography>
          <Typography color="white" fontSize={24}>2. 📷 Find our photographers & get snap-happy</Typography>
          <Typography color="white" fontSize={24}>3. 📱 Receive your AI gallery link via SMS/Email</Typography>
          <Typography color="white" fontSize={24}>4. ⬇️ Download your faves</Typography>
          <Typography color="white" fontSize={24}>5. 👵 Share with granny & post that story (don't forget to tag us!)</Typography>

          <br></br>

          <Grid item xs={12} className="inputs">
            <Grid item className="PhoneControl">
              <PhoneInput placeholder="Phone #" defaultCountry="IL" value={phoneNumber} onChange={setPhoneNumber} />
            </Grid>
          </Grid>
          <Grid item className="inputs">
            <div className="PhoneControl">
              <input type="text" value={email} onChange={e => setEmailValue(e.target.value)} placeholder="Your Mail" />
            </div>
          </Grid>
          {!validatePhone &&
            <Grid item style={{ color: "red" }} fontSize={15} xs={12}>We can't continue without a valid your Phone Number or Email</Grid>
          }

          {/* <Grid item>ביי! איפה הצלם? איפה הצ׳ייסר?? <Emoji symbol="🥂" label="smile"></Emoji><Emoji symbol="🍹" label="smile"></Emoji><Emoji symbol="🍸" label="smile"></Emoji></Grid> */}
        </Grid>
      </Grid>
    </Grid>,
    <Grid container spacing={2} className="App MobileApp">
      <Grid item xs={12}>
        <div>
          {!photo ?
            <>
              <Typography color="white" fontSize={24}>*Selfie Time!* 🤳</Typography>

              <br>
              </br>
              <Typography color="white" fontSize={24}>• Focus on your fabulous face</Typography>
              <Typography color="white" fontSize={24}>• In a dimly lit space? Flash = your new BFF</Typography>

              <CameraIcon style={{ width: '40%', height: '40%' }} onClick={handleCapture}> צלם </CameraIcon>
            </>
            : <div>
              <Grid item xs={12}>
                <img src={photo} style={{ width: "250px" }} alt="Uploaded" />
              </Grid>
              <Grid item xs={12}>
                <Typography>Ready to PicIt? Let's make some memories! 🎊📸</Typography>
              </Grid>

            </div>
          }
        </div>
      </Grid>
      <input
        accept="image/*"
        ref={hiddenFileInput}
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={handleFileInput}
        style={{ display: 'none' }} />

    </Grid>
  ]

  return (
    <>
      <Grid container spacing={2} className="App">
        <Grid item xs={12}>
          <h1 className="AppTitle">📸 Welcome to PicIt: Your AI-Powered Photo Gallery! 🎉</h1>
        </Grid>
      </Grid>
      <Box>
        {event ?
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Grid container spacing={2} className="App MobileApp">
                <Grid item xs={12}>
                  <Typography color="white" sx={12}>
                    Ready to PicIt? Let's make some memories! 🎊📸
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <React.Fragment>
                {stepsComponents[activeStep]}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    style={activeStep === 0 ? { color: "white" } : { color: "black" }}
                    variant="contained"
                    size="large"
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={handleNext} variant="contained" size="large">
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box> :
        openEvents ? <EventsMenu events={openEvents} setEvent={setEvent}/> : <Typography>NO Event Enterd {event} VAR</Typography>}
        <Loading loading={loading} background="#8e8e8ec9" loaderColor="##ffffff" />
      </Box>
    </>
  )
}

export default App;
